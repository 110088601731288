import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams, useLocation } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import Snackbar from '../Snackbar/Snackbar';
import usePopupWindow from '../../hooks/usePopupWindow/usePopupWindow';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const classes = useStyles();
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [crossAuth, setCrossAuth] = useState<Boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSnackbarMessage, setIsSnackbarMessage] = useState<string>('');

  const getCookie = useCallback(cookieName => {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split('; ').find(cookie => cookie.startsWith(name));

    if (cookieArray) {
      return cookieArray.substring(name.length, cookieArray.length);
    } else {
      return null;
    }
  }, []);

  const checkAuthorization = useCallback(
    async URLRoomName => {
      // Get the user data from the cookie
      const cookie = getCookie('video_conf');
      const [userId, userType] = (cookie || '').split('-');

      // Prepare the request parameters
      const params = new URLSearchParams({
        uid: URLRoomName,
        userId: userId || '',
        userType: userType || '',
      });

      // Construct the complete URL
      const baseUrl = `${process.env.REACT_APP_DPURL}api/v11/Twilio?${params.toString()}`;

      let data = await fetch(baseUrl)
        .then(r => r.json())
        .catch(err => err);
      return data;
    },
    [getCookie]
  );

  const processRoomData = useCallback(async () => {
    let roomData = await checkAuthorization(URLRoomName);
    if (roomData.success) {
      setCrossAuth(true);
      setRoomName(roomData.data.course_name);
      setName(roomData.data.participantName);

      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    } else {
      setCrossAuth(false);
      setIsSnackbarOpen(!roomData.success);
      setIsSnackbarMessage(roomData.message);
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    processRoomData(); // Call the function within the useEffect
  }, [processRoomData]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${URLRoomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  const redirectToDP = useCallback(() => {
    let config = {
      reffer: process.env.REACT_APP_FULLURL || 'http://localhost:3000/',
      from: 'video-dominionprep',
      action: 'cross-authorization',
    };

    handleWindowPopup(config);
  }, []);

  const { handleWindowPopup } = usePopupWindow({
    url: process.env.REACT_APP_DPURL + 'signin/index',
    popupProperties: 'width=1000,height=600,status=yes,scrollbars=yes',
    onPopupClose: () => {
      processRoomData(); // Call the function within the useEffect
    },
  });

  return (
    <IntroContainer>
      <Snackbar
        open={isSnackbarOpen}
        handleClose={() => setIsSnackbarOpen(false)}
        headline="Authentication failed:"
        message={isSnackbarMessage}
        variant="warning"
      />
      {!crossAuth && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Sign in to join a room
          </Typography>
          <Typography variant="body1">Sign in using your Dominion Prep Account</Typography>
          <Button variant="contained" onClick={redirectToDP} className={classes.googleButton}>
            Sign in with Dominion Prep
          </Button>
        </>
      )}
      {crossAuth && (
        <>
          <MediaErrorSnackbar error={mediaError} />
          {step === Steps.roomNameStep && (
            <RoomNameScreen
              name={name}
              roomName={roomName}
              setName={setName}
              setRoomName={setRoomName}
              handleSubmit={handleSubmit}
            />
          )}

          {step === Steps.deviceSelectionStep && (
            <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
          )}
        </>
      )}
    </IntroContainer>
  );
}
