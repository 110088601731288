import { useEffect, useRef } from 'react';

interface PopupWindowProps {
  url: string;
  onPopupClose: () => void;
  popupProperties?: string;
}

interface PopupWindowResult {
  handleWindowPopup: (queryData?: Record<string, string>) => void;
}

const usePopupWindow = ({
  url,
  onPopupClose,
  popupProperties = 'width=200,height=200',
}: PopupWindowProps): PopupWindowResult => {
  const timer = useRef<number | undefined>();

  const handleWindowPopup = (queryData?: Record<string, string>) => {
    // Build the query string from the provided queryData
    const queryString = queryData ? '?' + new URLSearchParams(queryData).toString() : '';

    // Construct the complete URL with the query string
    const fullUrl = url + queryString;

    const windowPopup = window.open(fullUrl, 'popup', popupProperties);

    if (windowPopup) {
      timer.current = window.setInterval(() => {
        if (windowPopup.closed) {
          window.clearInterval(timer.current);
          onPopupClose();
        }
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        window.clearInterval(timer.current);
      }
    };
  }, []);

  return { handleWindowPopup };
};

export default usePopupWindow;
