import React, { SVGProps } from 'react';

export default function VideoLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="64" height="44" viewBox="0 0 64 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.66667 42H42C45.682 42 48.6667 39.0153 48.6667 35.3333V10.5714V8.66667C48.6667 4.98477 45.682 2 42 2H8.66667C4.98477 2 2 4.98477 2 8.66667V35.3333C2 39.0153 4.98477 42 8.66667 42Z"
        stroke="#ffffff"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.6667 15.3333C14.5871 15.3333 15.3333 14.5871 15.3333 13.6667C15.3333 12.7462 14.5871 12 13.6667 12C12.7462 12 12 12.7462 12 13.6667C12 14.5871 12.7462 15.3333 13.6667 15.3333Z"
        stroke="#ffffff"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        d="M48.6667 15.3333L62 5.33334V38.6667L48.6667 28.6667"
        stroke="#ffffff"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </svg>
  );
}
