import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    // <svg fill="#F22F46" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    //   <path d="M15 0C6.7 0 0 6.7 0 15s6.7 15 15 15 15-6.7 15-15S23.3 0 15 0zm0 26C8.9 26 4 21.1 4 15S8.9 4 15 4s11 4.9 11 11-4.9 11-11 11zm6.8-14.7c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1zm0 7.4c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1zm-7.4 0c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1zm0-7.4c0 1.7-1.4 3.1-3.1 3.1S8.2 13 8.2 11.3s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1z" />
    // </svg>
    <svg width={300} height={51} viewBox="0 0 300 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M66.0566 17.9224V3.57495H72.6995C77.5511 3.57495 80.6445 6.21997 80.6445 10.7487C80.6445 15.2774 77.5511 17.9224 72.6995 17.9224H66.0566ZM69.692 14.6604H72.4153C75.1583 14.6604 77.0157 13.3084 77.0157 10.7487C77.0157 8.18897 75.1187 6.83692 72.4153 6.83692H69.692V14.6604Z"
        fill="white"
      />
      <path
        d="M86.3486 10.7487C86.3486 6.38405 89.323 3.29272 94.2738 3.29272C99.2245 3.29272 102.219 6.38405 102.219 10.7487C102.219 15.1133 99.2245 18.2046 94.2738 18.2046C89.3164 18.2046 86.3486 15.1133 86.3486 10.7487ZM98.5437 10.7487C98.5437 8.59589 97.2218 6.58751 94.2936 6.58751C91.3655 6.58751 90.0237 8.59589 90.0237 10.7487C90.0237 12.9014 91.3655 14.9098 94.2936 14.9098C97.2218 14.9098 98.5437 12.9014 98.5437 10.7487Z"
        fill="white"
      />
      <path
        d="M121.844 3.57495H126.054V17.9158H122.485V9.0422L117.369 17.9158H116.999L111.923 9.06189V17.9158H108.373V3.57495H112.848L117.349 11.4444L121.844 3.57495Z"
        fill="white"
      />
      <path d="M132.657 17.9224V3.57495H136.286V17.9158H132.657V17.9224Z" fill="white" />
      <path
        d="M142.889 17.9224V3.57495H146.564L154.158 12.4486V3.57495H157.787V17.9158H154.343L146.524 8.94375V17.9158H142.889V17.9224Z"
        fill="white"
      />
      <path d="M164.397 17.9224V3.57495H168.027V17.9158H164.397V17.9224Z" fill="white" />
      <path
        d="M174.18 10.7487C174.18 6.38405 177.154 3.29272 182.105 3.29272C187.056 3.29272 190.05 6.38405 190.05 10.7487C190.05 15.1133 187.056 18.2046 182.105 18.2046C177.147 18.2046 174.18 15.1133 174.18 10.7487ZM186.375 10.7487C186.375 8.59589 185.053 6.58751 182.125 6.58751C179.197 6.58751 177.855 8.59589 177.855 10.7487C177.855 12.9014 179.197 14.9098 182.125 14.9098C185.053 14.9098 186.375 12.9014 186.375 10.7487Z"
        fill="white"
      />
      <path
        d="M196.197 17.9224V3.57495H199.872L207.467 12.4486V3.57495H211.096V17.9158H207.652L199.833 8.94375V17.9158H196.197V17.9224Z"
        fill="white"
      />
      <path
        d="M226.364 17.9224V3.57495H234.679C238.374 3.57495 240.397 5.60302 240.397 8.53683C240.397 11.4641 238.374 13.4724 234.679 13.4724H229.993V17.9224H226.364ZM230 10.3352H234.58C235.922 10.3352 236.768 9.63946 236.768 8.51057C236.768 7.48669 235.942 6.7516 234.58 6.7516H230V10.3352Z"
        fill="white"
      />
      <path
        d="M260.993 17.9224H255.751L249.908 12.186V17.9224H246.279V3.57495H255.11C258.825 3.57495 260.682 5.39956 260.682 8.30711C260.682 10.8274 259.135 12.6914 255.996 12.9408L260.993 17.9224ZM254.7 6.7516H249.915V10.2564H254.7C256.082 10.2564 257.053 9.5804 257.053 8.47119C257.053 7.467 256.108 6.7516 254.7 6.7516Z"
        fill="white"
      />
      <path
        d="M267.107 17.9224V3.57495H279.449V6.81067H270.736V9.00282H279.257V12.2385H270.736V14.6735H279.548V17.9093H267.107V17.9224Z"
        fill="white"
      />
      <path
        d="M285.945 17.9224V3.57495H294.26C297.955 3.57495 299.978 5.60302 299.978 8.53683C299.978 11.4641 297.955 13.4724 294.26 13.4724H289.574V17.9224H285.945ZM289.581 10.3352H294.161C295.503 10.3352 296.349 9.63946 296.349 8.51057C296.349 7.48669 295.523 6.7516 294.161 6.7516H289.581V10.3352Z"
        fill="white"
      />
      <path
        d="M70.1855 32.8447L72.9024 26.9246H74.1055L76.8223 32.8447H75.6721L75.1367 31.5911H71.8513L71.3159 32.8447H70.1855ZM72.248 30.6525H74.7268L73.4841 27.7712L72.248 30.6525Z"
        fill="white"
      />
      <path
        d="M79.7371 28.3684C80.5038 28.3684 81.0261 28.5259 81.3367 28.8082L81.4954 29.9567C81.145 29.5236 80.5832 29.2151 79.8957 29.2151C78.9174 29.2151 78.3092 29.8583 78.3092 30.6524C78.3092 31.4466 78.8909 32.0964 79.8825 32.0964C80.5501 32.0964 81.1781 31.8273 81.5681 31.3547L81.4095 32.4967C81.0194 32.779 80.4642 32.943 79.7371 32.943C78.296 32.943 77.2979 31.9782 77.2979 30.6524C77.3111 29.3267 78.3026 28.3684 79.7371 28.3684Z"
        fill="white"
      />
      <path
        d="M84.78 28.3684C85.5468 28.3684 86.069 28.5259 86.3797 28.8082L86.5384 29.9567C86.188 29.5236 85.6261 29.2151 84.9387 29.2151C83.9603 29.2151 83.3522 29.8583 83.3522 30.6524C83.3522 31.4466 83.9339 32.0964 84.9254 32.0964C85.5931 32.0964 86.2211 31.8273 86.6111 31.3547L86.4524 32.4967C86.0624 32.779 85.5072 32.943 84.78 32.943C83.339 32.943 82.3408 31.9782 82.3408 30.6524C82.354 29.3267 83.339 28.3684 84.78 28.3684Z"
        fill="white"
      />
      <path
        d="M92.1238 30.8691H88.4022C88.4882 31.6435 89.0566 32.1358 90.1473 32.1358C90.9472 32.1358 91.628 31.8142 92.0908 31.3351L91.8859 32.4508C91.4826 32.7527 90.8018 32.9496 90.0812 32.9496C88.3427 32.9496 87.3975 31.952 87.3975 30.659C87.3975 29.3726 88.3097 28.375 89.8499 28.375C91.324 28.375 92.1238 29.3726 92.1238 30.6525V30.8691ZM88.4419 30.1471H91.1587C91.0331 29.563 90.6035 29.0904 89.8367 29.0904C89.0566 29.0904 88.5873 29.5498 88.4419 30.1471Z"
        fill="white"
      />
      <path d="M93.1416 32.8446V26.6685L94.1464 26.5176V32.8446H93.1416Z" fill="white" />
      <path
        d="M99.9295 30.8691H96.2079C96.2938 31.6435 96.8623 32.1358 97.953 32.1358C98.7529 32.1358 99.4337 31.8142 99.8964 31.3351L99.6915 32.4508C99.2883 32.7527 98.6074 32.9496 97.8869 32.9496C96.1484 32.9496 95.2031 31.952 95.2031 30.659C95.2031 29.3726 96.1153 28.375 97.6555 28.375C99.1296 28.375 99.9295 29.3726 99.9295 30.6525V30.8691ZM96.2476 30.1471H98.9644C98.8388 29.563 98.4091 29.0904 97.6423 29.0904C96.8623 29.0904 96.393 29.5498 96.2476 30.1471Z"
        fill="white"
      />
      <path
        d="M100.961 32.8447V28.4735H101.966V30.1997C102.29 29.2086 103.017 28.6967 103.757 28.3882L104.022 29.4514C103.374 29.6746 102.521 29.9568 101.966 30.5016V32.8447H100.961Z"
        fill="white"
      />
      <path
        d="M105.978 30.2981L107.518 29.9699V29.9108C107.518 29.4645 107.307 29.1954 106.626 29.1954C105.978 29.1954 105.211 29.517 104.728 29.983L104.874 28.9657C105.337 28.585 105.998 28.375 106.764 28.375C107.934 28.375 108.529 28.8673 108.529 29.9174V32.8512H107.525V31.8798C107.287 32.3261 106.632 32.9562 105.687 32.9562C104.781 32.9562 104.299 32.4377 104.299 31.6435C104.299 30.8494 104.973 30.5081 105.978 30.2981ZM106.07 32.208C106.665 32.208 107.214 31.8798 107.518 31.4795V30.5081L106.15 30.8888C105.608 31.0397 105.297 31.2432 105.297 31.5976C105.297 31.9914 105.548 32.208 106.07 32.208Z"
        fill="white"
      />
      <path
        d="M110.427 31.2693V29.3135H109.362V28.4668H110.427V27.1541L111.431 27.0032V28.4734H113.209V29.32H111.431V31.1906C111.431 31.7747 111.663 32.0766 112.264 32.0766C112.621 32.0766 112.932 31.9585 113.15 31.8207L113.011 32.7395C112.813 32.8577 112.449 32.9364 112.033 32.9364C110.982 32.9364 110.427 32.3457 110.427 31.2693Z"
        fill="white"
      />
      <path
        d="M118.623 30.8691H114.901C114.987 31.6435 115.556 32.1358 116.646 32.1358C117.446 32.1358 118.127 31.8142 118.59 31.3351L118.385 32.4508C117.982 32.7527 117.301 32.9496 116.58 32.9496C114.842 32.9496 113.896 31.952 113.896 30.659C113.896 29.3726 114.809 28.375 116.349 28.375C117.823 28.375 118.623 29.3726 118.623 30.6525V30.8691ZM114.941 30.1471H117.658C117.532 29.563 117.102 29.0904 116.336 29.0904C115.556 29.0904 115.086 29.5498 114.941 30.1471Z"
        fill="white"
      />
      <path
        d="M121.584 28.3685C122.47 28.3685 123.051 28.887 123.296 29.5892V26.6751L124.301 26.5242V32.8512H123.296V31.7355C123.038 32.4443 122.47 32.9562 121.584 32.9562C120.235 32.9562 119.402 31.9849 119.402 30.6656C119.402 29.3464 120.235 28.3685 121.584 28.3685ZM121.848 32.1096C122.463 32.1096 122.979 31.7945 123.296 31.2891V30.029C122.979 29.5236 122.47 29.2086 121.855 29.2086C120.89 29.2086 120.407 29.8846 120.407 30.6525C120.407 31.4335 120.896 32.1096 121.848 32.1096Z"
        fill="white"
      />
      <path
        d="M133.336 32.8447H131.836L128.616 30.239V32.8447H127.592V26.9246H131.042C132.411 26.9246 133.204 27.6465 133.204 28.7163C133.204 29.7665 132.411 30.495 131.069 30.495H130.395L133.336 32.8447ZM130.923 27.8237H128.616V29.7205H130.923C131.67 29.7205 132.186 29.3661 132.186 28.7623C132.186 28.1781 131.69 27.8237 130.923 27.8237Z"
        fill="white"
      />
      <path
        d="M138.643 30.8691H134.922C135.008 31.6435 135.576 32.1358 136.667 32.1358C137.467 32.1358 138.148 31.8142 138.61 31.3351L138.405 32.4508C138.002 32.7527 137.321 32.9496 136.601 32.9496C134.862 32.9496 133.917 31.952 133.917 30.659C133.917 29.3726 134.829 28.375 136.369 28.375C137.844 28.375 138.643 29.3726 138.643 30.6525V30.8691ZM134.961 30.1471H137.678C137.553 29.563 137.123 29.0904 136.356 29.0904C135.576 29.0904 135.107 29.5498 134.961 30.1471Z"
        fill="white"
      />
      <path
        d="M139.463 31.4729C140.044 31.9323 140.811 32.1817 141.518 32.1817C142.186 32.1817 142.583 31.9782 142.583 31.5582C142.583 31.2891 142.378 31.125 141.968 31.0659L140.824 30.8953C139.932 30.7575 139.456 30.3965 139.456 29.6942C139.456 28.841 140.229 28.3684 141.459 28.3684C142.127 28.3684 142.702 28.5062 143.026 28.7294L143.197 29.7205C142.814 29.3923 142.213 29.1298 141.492 29.1298C140.791 29.1298 140.395 29.2807 140.395 29.6286C140.395 29.8845 140.593 29.983 140.976 30.0355L142.12 30.193C143.039 30.3177 143.528 30.7115 143.528 31.4597C143.528 32.4049 142.682 32.9365 141.525 32.9365C140.705 32.9365 140.091 32.7527 139.641 32.477L139.463 31.4729Z"
        fill="white"
      />
      <path
        d="M144.433 28.4734H145.438V31.0593C145.438 31.7091 145.775 32.0898 146.45 32.0898C146.978 32.0898 147.368 31.8404 147.639 31.4728V28.4734H148.644V32.8446H147.639V31.7813C147.408 32.4836 146.925 32.9496 146.04 32.9496C144.989 32.9496 144.427 32.2539 144.427 31.2497V28.4734H144.433Z"
        fill="white"
      />
      <path d="M149.939 32.8446V26.6685L150.944 26.5176V32.8446H149.939Z" fill="white" />
      <path
        d="M152.881 31.2695V29.3137H151.816V28.467H152.881V27.1543L153.885 26.9968V28.467H155.664V29.3137H153.885V31.1842C153.885 31.7684 154.117 32.0703 154.718 32.0703C155.075 32.0703 155.386 31.9521 155.604 31.8143L155.465 32.7332C155.267 32.8513 154.903 32.9301 154.487 32.9301C153.429 32.9366 152.881 32.3459 152.881 31.2695Z"
        fill="white"
      />
      <path
        d="M156.378 31.4729C156.959 31.9323 157.726 32.1817 158.434 32.1817C159.101 32.1817 159.498 31.9782 159.498 31.5582C159.498 31.2891 159.293 31.125 158.883 31.0659L157.739 30.8953C156.847 30.7575 156.371 30.3965 156.371 29.6942C156.371 28.841 157.144 28.3684 158.374 28.3684C159.042 28.3684 159.617 28.5062 159.941 28.7294L160.113 29.7205C159.729 29.3923 159.128 29.1298 158.407 29.1298C157.706 29.1298 157.31 29.2807 157.31 29.6286C157.31 29.8845 157.508 29.983 157.891 30.0355L159.035 30.193C159.954 30.3177 160.443 30.7115 160.443 31.4597C160.443 32.4049 159.597 32.9365 158.44 32.9365C157.62 32.9365 157.006 32.7527 156.556 32.477L156.378 31.4729Z"
        fill="white"
      />
      <path
        d="M161.328 32.313C161.328 31.952 161.573 31.7026 161.949 31.7026C162.333 31.7026 162.571 31.9455 162.571 32.313C162.571 32.6674 162.333 32.9103 161.949 32.9103C161.573 32.9103 161.328 32.6674 161.328 32.313Z"
        fill="white"
      />
      <path
        d="M165.803 32.8447V26.9246H170.694V27.8369H166.827V29.353H170.655V30.2653H166.827V31.9324H170.747V32.8447H165.803Z"
        fill="white"
      />
      <path
        d="M171.561 32.8446L173.266 30.613L171.587 28.4734H172.744L173.874 29.9107L174.998 28.4734H176.128L174.449 30.6393L176.188 32.8446H175.005L173.841 31.3481L172.698 32.8446H171.561Z"
        fill="white"
      />
      <path
        d="M177.701 31.2695V29.3137H176.637V28.467H177.701V27.1543L178.706 26.9968V28.467H180.484V29.3137H178.706V31.1842C178.706 31.7684 178.937 32.0703 179.539 32.0703C179.896 32.0703 180.206 31.9521 180.424 31.8143L180.286 32.7332C180.087 32.8513 179.724 32.9301 179.307 32.9301C178.256 32.9366 177.701 32.3459 177.701 31.2695Z"
        fill="white"
      />
      <path
        d="M181.415 32.8447V28.4735H182.42V30.1997C182.744 29.2086 183.471 28.6967 184.211 28.3882L184.476 29.4514C183.828 29.6746 182.975 29.9568 182.42 30.5016V32.8447H181.415Z"
        fill="white"
      />
      <path
        d="M186.432 30.2981L187.972 29.9699V29.9108C187.972 29.4645 187.761 29.1954 187.08 29.1954C186.432 29.1954 185.665 29.517 185.183 29.983L185.328 28.9657C185.791 28.585 186.452 28.375 187.219 28.375C188.389 28.375 188.984 28.8673 188.984 29.9174V32.8512H187.979V31.8798C187.741 32.3261 187.086 32.9562 186.141 32.9562C185.235 32.9562 184.753 32.4377 184.753 31.6435C184.753 30.8494 185.427 30.5081 186.432 30.2981ZM186.524 32.208C187.119 32.208 187.668 31.8798 187.972 31.4795V30.5081L186.604 30.8888C186.062 31.0397 185.751 31.2432 185.751 31.5976C185.751 31.9914 186.002 32.208 186.524 32.208Z"
        fill="white"
      />
      <path
        d="M190.028 30.6524C190.028 29.3529 191 28.3684 192.487 28.3684C193.981 28.3684 194.946 29.3529 194.946 30.6524C194.946 31.952 193.975 32.943 192.487 32.943C191 32.9496 190.028 31.9585 190.028 30.6524ZM193.961 30.6524C193.961 29.8583 193.4 29.2085 192.494 29.2085C191.588 29.2085 191.026 29.8583 191.026 30.6524C191.026 31.4532 191.588 32.1095 192.494 32.1095C193.393 32.1095 193.961 31.4597 193.961 30.6524Z"
        fill="white"
      />
      <path
        d="M196.01 32.8447V28.4735H197.015V30.1997C197.338 29.2086 198.066 28.6967 198.806 28.3882L199.07 29.4514C198.423 29.6746 197.57 29.9568 197.015 30.5016V32.8447H196.01Z"
        fill="white"
      />
      <path
        d="M201.662 28.3685C202.548 28.3685 203.129 28.887 203.374 29.5892V26.6751L204.379 26.5242V32.8512H203.374V31.7355C203.116 32.4443 202.548 32.9562 201.662 32.9562C200.313 32.9562 199.48 31.9849 199.48 30.6656C199.48 29.3464 200.32 28.3685 201.662 28.3685ZM201.926 32.1096C202.541 32.1096 203.057 31.7945 203.374 31.2891V30.029C203.057 29.5236 202.548 29.2086 201.933 29.2086C200.968 29.2086 200.485 29.8846 200.485 30.6525C200.492 31.4335 200.974 32.1096 201.926 32.1096Z"
        fill="white"
      />
      <path
        d="M205.548 27.2789C205.548 26.9376 205.779 26.6882 206.176 26.6882C206.579 26.6882 206.804 26.9442 206.804 27.2789C206.804 27.6202 206.572 27.8696 206.176 27.8696C205.779 27.8762 205.548 27.6202 205.548 27.2789ZM205.673 32.8446V28.4735H206.678V32.8446H205.673Z"
        fill="white"
      />
      <path
        d="M207.974 32.8446V28.4734H208.978V29.5367C209.21 28.8082 209.712 28.3684 210.598 28.3684C211.656 28.3684 212.224 29.0641 212.224 30.0683V32.8446H211.219V30.2652C211.219 29.6154 210.862 29.2348 210.181 29.2348C209.672 29.2348 209.256 29.471 208.978 29.8517V32.8446H207.974Z"
        fill="white"
      />
      <path
        d="M215.013 30.2981L216.553 29.9699V29.9108C216.553 29.4645 216.342 29.1954 215.661 29.1954C215.013 29.1954 214.246 29.517 213.764 29.983L213.909 28.9657C214.372 28.585 215.033 28.375 215.8 28.375C216.97 28.375 217.565 28.8673 217.565 29.9174V32.8512H216.56V31.8798C216.322 32.3261 215.667 32.9562 214.722 32.9562C213.817 32.9562 213.334 32.4377 213.334 31.6435C213.334 30.8494 214.008 30.5081 215.013 30.2981ZM215.112 32.208C215.707 32.208 216.256 31.8798 216.56 31.4795V30.5081L215.191 30.8888C214.649 31.0397 214.339 31.2432 214.339 31.5976C214.332 31.9914 214.59 32.208 215.112 32.208Z"
        fill="white"
      />
      <path
        d="M218.854 32.8447V28.4735H219.858V30.1997C220.182 29.2086 220.909 28.6967 221.65 28.3882L221.914 29.4514C221.266 29.6746 220.414 29.9568 219.858 30.5016V32.8447H218.854Z"
        fill="white"
      />
      <path
        d="M222.575 34.2229L222.449 33.3237C222.674 33.4353 222.925 33.4746 223.15 33.4746C223.619 33.4746 223.791 33.2384 223.983 32.7396L224.042 32.5886L222.218 28.4734H223.302L224.591 31.5122L225.86 28.4734H226.904L224.915 33.153C224.558 33.9931 224.194 34.3738 223.355 34.3738C223.071 34.3673 222.78 34.3147 222.575 34.2229Z"
        fill="white"
      />
      <path
        d="M229.455 29.8846C229.455 28.2175 230.645 26.8064 232.641 26.8064C234.638 26.8064 235.827 28.2175 235.827 29.8846C235.827 31.5517 234.638 32.9628 232.641 32.9628C230.645 32.9628 229.455 31.5517 229.455 29.8846ZM234.796 29.8846C234.796 28.7491 234.056 27.7187 232.641 27.7187C231.227 27.7187 230.486 28.7491 230.486 29.8846C230.486 31.0201 231.227 32.0505 232.641 32.0505C234.056 32.0505 234.796 31.0201 234.796 29.8846Z"
        fill="white"
      />
      <path
        d="M236.925 34.3607V28.4734H237.93V29.5892C238.187 28.8803 238.756 28.3684 239.642 28.3684C240.99 28.3684 241.823 29.3398 241.823 30.659C241.823 31.9717 240.99 32.943 239.642 32.943C238.756 32.943 238.174 32.4245 237.93 31.7223V34.2032L236.925 34.3607ZM237.93 31.2891C238.247 31.7945 238.756 32.1095 239.371 32.1095C240.336 32.1095 240.818 31.4335 240.818 30.6656C240.818 29.8845 240.336 29.2085 239.377 29.2085C238.762 29.2085 238.247 29.5236 237.93 30.0289V31.2891Z"
        fill="white"
      />
      <path
        d="M242.88 34.3607V28.4734H243.885V29.5892C244.142 28.8803 244.711 28.3684 245.597 28.3684C246.945 28.3684 247.778 29.3398 247.778 30.659C247.778 31.9717 246.945 32.943 245.597 32.943C244.711 32.943 244.129 32.4245 243.885 31.7223V34.2032L242.88 34.3607ZM243.885 31.2891C244.202 31.7945 244.711 32.1095 245.326 32.1095C246.291 32.1095 246.773 31.4335 246.773 30.6656C246.773 29.8845 246.291 29.2085 245.332 29.2085C244.718 29.2085 244.202 29.5236 243.885 30.0289V31.2891Z"
        fill="white"
      />
      <path
        d="M248.591 30.6524C248.591 29.3529 249.563 28.3684 251.05 28.3684C252.544 28.3684 253.509 29.3529 253.509 30.6524C253.509 31.952 252.537 32.943 251.05 32.943C249.563 32.9496 248.591 31.9585 248.591 30.6524ZM252.517 30.6524C252.517 29.8583 251.955 29.2085 251.05 29.2085C250.144 29.2085 249.582 29.8583 249.582 30.6524C249.582 31.4532 250.144 32.1095 251.05 32.1095C251.955 32.1095 252.517 31.4597 252.517 30.6524Z"
        fill="white"
      />
      <path
        d="M254.572 32.8447V28.4735H255.577V30.1997C255.901 29.2086 256.628 28.6967 257.368 28.3882L257.633 29.4514C256.985 29.6746 256.132 29.9568 255.577 30.5016V32.8447H254.572Z"
        fill="white"
      />
      <path
        d="M259.034 31.2695V29.3137H257.97V28.467H259.034V27.1543L260.039 26.9968V28.467H261.817V29.3137H260.039V31.1842C260.039 31.7684 260.27 32.0703 260.872 32.0703C261.229 32.0703 261.539 31.9521 261.757 31.8143L261.619 32.7332C261.42 32.8513 261.057 32.9301 260.64 32.9301C259.583 32.9366 259.034 32.3459 259.034 31.2695Z"
        fill="white"
      />
      <path
        d="M262.703 28.4734H263.708V31.0593C263.708 31.7091 264.045 32.0898 264.719 32.0898C265.248 32.0898 265.638 31.8404 265.909 31.4728V28.4734H266.914V32.8446H265.909V31.7813C265.678 32.4836 265.195 32.9496 264.309 32.9496C263.258 32.9496 262.696 32.2539 262.696 31.2497V28.4734H262.703Z"
        fill="white"
      />
      <path
        d="M268.209 32.8446V28.4734H269.214V29.5367C269.445 28.8082 269.947 28.3684 270.833 28.3684C271.891 28.3684 272.459 29.0641 272.459 30.0683V32.8446H271.455V30.2652C271.455 29.6154 271.098 29.2348 270.417 29.2348C269.908 29.2348 269.491 29.471 269.214 29.8517V32.8446H268.209Z"
        fill="white"
      />
      <path
        d="M273.582 27.2789C273.582 26.9376 273.813 26.6882 274.21 26.6882C274.613 26.6882 274.838 26.9442 274.838 27.2789C274.838 27.6202 274.607 27.8696 274.21 27.8696C273.813 27.8762 273.582 27.6202 273.582 27.2789ZM273.714 32.8446V28.4735H274.719V32.8446H273.714Z"
        fill="white"
      />
      <path
        d="M276.649 31.2695V29.3137H275.585V28.467H276.649V27.1543L277.654 26.9968V28.467H279.432V29.3137H277.654V31.1842C277.654 31.7684 277.885 32.0703 278.487 32.0703C278.844 32.0703 279.155 31.9521 279.373 31.8143L279.234 32.7332C279.036 32.8513 278.672 32.9301 278.256 32.9301C277.204 32.9366 276.649 32.3459 276.649 31.2695Z"
        fill="white"
      />
      <path
        d="M280.231 27.2789C280.231 26.9376 280.463 26.6882 280.859 26.6882C281.263 26.6882 281.487 26.9442 281.487 27.2789C281.487 27.6202 281.256 27.8696 280.859 27.8696C280.463 27.8762 280.231 27.6202 280.231 27.2789ZM280.364 32.8446V28.4735H281.368V32.8446H280.364Z"
        fill="white"
      />
      <path
        d="M287.153 30.8691H283.432C283.517 31.6435 284.086 32.1358 285.177 32.1358C285.976 32.1358 286.657 31.8142 287.12 31.3351L286.915 32.4508C286.512 32.7527 285.831 32.9496 285.111 32.9496C283.372 32.9496 282.427 31.952 282.427 30.659C282.427 29.3726 283.339 28.375 284.879 28.375C286.353 28.375 287.153 29.3726 287.153 30.6525V30.8691ZM283.471 30.1471H286.188C286.062 29.563 285.633 29.0904 284.866 29.0904C284.086 29.0904 283.617 29.5498 283.471 30.1471Z"
        fill="white"
      />
      <path
        d="M287.978 31.4729C288.56 31.9323 289.327 32.1817 290.034 32.1817C290.702 32.1817 291.098 31.9782 291.098 31.5582C291.098 31.2891 290.893 31.125 290.484 31.0659L289.34 30.8953C288.448 30.7575 287.972 30.3965 287.972 29.6942C287.972 28.841 288.745 28.3684 289.975 28.3684C290.642 28.3684 291.217 28.5062 291.541 28.7294L291.713 29.7205C291.33 29.3923 290.728 29.1298 290.008 29.1298C289.307 29.1298 288.91 29.2807 288.91 29.6286C288.91 29.8845 289.109 29.983 289.492 30.0355L290.636 30.193C291.554 30.3177 292.044 30.7115 292.044 31.4597C292.044 32.4049 291.198 32.9365 290.041 32.9365C289.221 32.9365 288.606 32.7527 288.157 32.477L287.978 31.4729Z"
        fill="white"
      />
      <path
        d="M292.923 32.313C292.923 31.952 293.167 31.7026 293.544 31.7026C293.928 31.7026 294.166 31.9455 294.166 32.313C294.166 32.6674 293.928 32.9103 293.544 32.9103C293.174 32.9103 292.923 32.6674 292.923 32.313Z"
        fill="white"
      />
      <path
        d="M33.9728 14.8171C32.1485 15.9138 30.6378 17.4605 29.5304 19.3164H29.524C28.4422 21.1303 27.7381 23.2464 27.4884 25.5242V49.0474L25.1136 50.3691L4.18793 38.7551C3.04211 38.1154 1.77467 36.5125 1.15375 35.2681C0.50083 33.9535 0.129552 32.449 0.116749 30.8812L0.110352 0.0324207L18.4563 10.2122C20.4535 11.316 22.5018 13.3196 23.8397 15.2037C22.233 17.2425 21.4649 20.7858 21.4649 20.7858C21.4649 20.7858 20.9848 19.7875 20.7031 19.3235H20.6967C19.5893 17.4675 18.0786 15.9208 16.2543 14.8241L4.86646 8.50389L4.86006 30.8672C4.87286 32.1186 5.52579 33.5738 6.43477 34.1784L22.7387 43.2264V25.4398L22.7579 25.1727C23.0652 21.9879 24.0381 19.0212 25.568 16.4622L25.5616 16.4551C27.0147 14.0297 29.3448 11.541 31.7644 10.2052L50.1104 0.0253906L50.0975 30.8742C50.0847 32.442 49.7199 33.9464 49.0606 35.2611C48.446 36.5055 47.1786 38.1154 46.0328 38.7481L29.8569 47.7258V41.8977L43.7859 34.1644C44.6949 33.5598 45.3478 32.1115 45.3606 30.8531H45.3542V8.49686L33.9728 14.8171Z"
        fill="#F15A22"
      />
      <path
        d="M50.104 30.8741C50.0912 32.4419 49.7263 33.9463 49.067 35.261C48.4524 36.5054 47.185 38.1153 46.0392 38.748L29.8633 47.7257V41.8976L43.7924 34.1643C44.7013 33.5597 45.3543 32.1114 45.3671 30.853H45.3607"
        fill="#F15A22"
      />
      <path
        d="M0.123047 30.8741C0.135849 32.4419 0.507118 33.9463 1.16004 35.261C1.78096 36.5054 3.04201 38.1153 4.19423 38.748L22.745 49.0403V43.2122L6.42825 34.1643C5.51928 33.5597 4.86636 32.1114 4.85355 30.853"
        fill="#F58220"
      />
      <path
        d="M0.110352 0.0253906L0.116749 30.8742C0.129552 32.442 0.50083 33.9464 1.15375 35.2611C1.77467 36.5055 3.03571 38.1084 4.18793 38.7481L8.92485 41.3774C7.77903 40.7377 6.51798 39.1348 5.90347 37.8974C5.25054 36.5828 4.87926 35.0783 4.86646 33.5106V31.057C4.86006 30.9867 4.85366 30.9164 4.85366 30.8531L4.86006 8.67262V2.66174L0.110352 0.0253906Z"
        fill="#FCAF17"
      />
      <path
        d="M50.1104 0.0253906L50.104 30.8742C50.0912 32.442 49.7199 33.9464 49.067 35.2611C48.4461 36.5055 47.185 38.1084 46.0328 38.7481L41.2959 41.3774C42.4417 40.7377 43.7028 39.1348 44.3173 37.8974C44.9702 36.5828 45.3415 35.0783 45.3543 33.5106V31.057C45.3607 30.9867 45.3671 30.9164 45.3671 30.8531L45.3607 8.67262V2.66174L50.1104 0.0253906Z"
        fill="#F15A22"
      />
    </svg>
  );
}
